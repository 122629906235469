import React, { useState,useEffect } from "react";
import {
  Row,
  Col,
  Tooltip,
  Select,
  Spin
} from "antd";
import BackNavigation from "../../utils/Components/BackNavigation";
import { connect } from "react-redux";
import * as actions from "./actions";
import "./danareports.css";

interface Props {
  reportList:any,
  getSerialNumberList :Function;
}
const Danareports: React.FC<Props> = (props) => 
  {  
    const [selectedMac, setSelectedMac] = useState<string | null>(null);
    const [selectedMasterTag, setSelectedMasterTag] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
           props.getSerialNumberList(null,null,null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
    const selectOptions = props.reportList.reports?.map((report: any) => ({ 
      value: report.mac,  
      label: report.mac,  
     }));

       // Function to handle the change of selected MAC and fetch corresponding masterTag
        const handleSelectChange = (value: string) => {
          setSelectedMac(value);
          setLoading(true);
          // Finding the report object based on the selected MAC and extract the masterTag
          const selectedReport = props.reportList.reports.find(
            (report: any) => report.mac === value
          );
          if (selectedReport) {
            setSelectedMasterTag(selectedReport.mastertag);
          }
        };

        // Function to handle iframe onLoad event to hide the spinner
        const handleIframeLoad = () => {
          setLoading(false); 
        };

        // Function to handle iframe error in case there's an issue loading the content
        const handleIframeError = () => {
          setLoading(false);
        };
        
        // Dynamic iframe URL based on selected MAC
        const iframeUrl = selectedMac
        ? `https://dana-example-iframe-fca4erghfkg6hkas.eastus-01.azurewebsites.net/home?param=${selectedMasterTag}`
        : "";
      
        useEffect(() => {
          if (loading) {
            const timer = setTimeout(() => {
              setLoading(false); // Stop spinner after 10 seconds
             // console.log("Loading timeout reached.");
            }, 10000); // 10 seconds timeout
            return () => clearTimeout(timer); // Clean up the timer on component unmount
          }
        }, [loading]);
    return(
      
      <div data-id="schedules-container" className="layout-content">
              <Row gutter={8}>
                 <Col xs={24} lg={24}>
                 <div className="content-header page-title">
                     <div style={{ display: "flex" }}>
                       <Tooltip title="Go To Previous Page">
                         <BackNavigation />
                      </Tooltip>
                      DANA Reports
                    </div>
                  </div>
                </Col>
      
               <Col xs={24} lg={4} className="content-header-controls">
               <div className="content-header">
                   <div style={{ display: "flex", marginTop: '20px' }}>
                   <Select
                     size="large"
                      style={{ width: '100%' }}
                      showSearch
                      placeholder="Select serial number"
                      options={selectOptions}
                      onChange={handleSelectChange}
                    />                     
                    </div>
                  </div>
                </Col>
                {loading ? (
                  <Col xs={24} lg={24}>
                  <div style={{ textAlign: "center", padding: "50px 0" }}>
                  <Spin size="large" tip="Loading..."  />
                  </div>
                  </Col>
                        ) : (
                  <iframe
                            src={iframeUrl} // Dynamic URL based on selected value
                            title="DANA Reports"
                            style={{
                              border: "none",
                              width: "100%",
                              height: "800px",
                              overflow: "hidden",
                            }}
                            scrolling="no"
                            allowFullScreen
                            onLoad={handleIframeLoad} // Trigger loading spinner hide when iframe loads
                            onError={handleIframeError} // Handle errors if iframe fails to load
                          />
                        )}
              </Row>
            </div>
    );
};

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    permissions: state.contextReducer.data,
    reportList:
      state.danaReportReducer.danaReportState.reportList,
  };
};

export default connect(mapStateToProps, actions)(Danareports);
