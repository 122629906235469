import Axios from "axios";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../utils/configurations";

export function getSerialNumberList(
  me: any, 
  callbackFnSuccess: any,
  callbackFnFailure: any,
) {   
  return async (dispatch: any) => {
      Axios.get(
          appConfigs.server.URL + "/api/assets-op-lite/?mode=org-and-below&status=active" , {
              responseType: "json",        
              headers: {},
              params: {}
          }
      )
      .then(response => {
              if (callbackFnSuccess != null) {        
                  let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? {} : response.data.data.assets[0];
                  callbackFnSuccess(data);
              }
              dispatch({
                type: "DANAREPORTS_GET_SUCCESS",
                payload: { data: response.data.data.assets },  
              });
              
          }
      )
      .catch(function (error) {
          if (callbackFnFailure != null) {
              callbackFnFailure(me, error);
          }
          errorResponse(me, error);
      });
  };
}