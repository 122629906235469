import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function danaReportState(state = initialState, action: any) {
  let data: any = null,
    payload: any;
  switch (action.type) {
 
    case "DANAREPORTS_GET_SUCCESS":
      data = action.payload.data;
      return immerProduce(state, (draft) => {
       draft.reportList.reports = data;
      });
    default:
      return state;
  }
}
